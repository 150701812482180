import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "компонент-list"
    }}>{`Компонент `}<inlineCode parentName="h1">{`<List>`}</inlineCode></h1>
    <p>{`Компонент `}<inlineCode parentName="p">{`<List>`}</inlineCode>{` нужен для отображения списка полученых данных.`}</p>
    <p>{`Для получения данных компонент `}<inlineCode parentName="p">{`<List>`}</inlineCode>{` вызывает `}<inlineCode parentName="p">{`getList`}</inlineCode>{` метод из `}<inlineCode parentName="p">{`<dataProvider>`}</inlineCode>{` для текущего ресурса`}</p>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`columns (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`array<object>`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actions (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`filters (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`perPage (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`view (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`'edit', 'show'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`viewOptions (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "columntype"
    }}>{`ColumnType`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`source (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Field (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FieldChildren (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`reference (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`link (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sortDirections (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`'descend', 'ascend'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sorter (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(a, b) => number;`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`const columns: ColumnT[] = [
    {
      title: 'ID',
      source: '_id',
      Field: TextField,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Author',
      source: 'author',
      Field: TextField,
    },
    {
      title: 'Title',
      source: 'title',
      Field: TextField,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Body',
      source: 'body',
      Field: TextField,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Created At',
      source: 'createdAt',
      Field: DateField,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  <List view="edit" columns={columns} />;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      